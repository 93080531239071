<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div id="pdfDom">
      <div>
        <el-row>
          <el-col :sm="6" :xs="24">
            <el-page-header @back="goBack" :content="$t(title)">
            </el-page-header>
          </el-col>
          <el-col :sm="18" :xs="24" class="col-flex">
            <el-button
              v-if="orderStatus == 301"
              type="success"
              @click="confirmOrderbyAdmin"
              >{{ $t("common.confirmOrder") }}</el-button
            >
            <!-- <el-button type="primary" @click="toGetPdf">{{
              $t("common.exportpdf")
            }}</el-button> -->

            <el-popover placement="top" width="250" v-model="visible">
              <p>{{ $t("common.exportAs") }}：</p>
              <div
                style="
                  text-align: center;
                  display: flex;
                  justify-content: center;
                "
              >
                <el-button
                  size="mini"
                  type="primary"
                  @click="toGetPdf('logistique')"
                  >{{ $t("common.LogisticsList") }}</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="toGetPdf('detail')"
                  >{{ $t("common.ListParticulars") }}</el-button
                >
              </div>
              <el-button slot="reference">{{
                $t("common.exportpdf")
              }}</el-button>
            </el-popover>
            <template
              v-if="orderData.status > 0 && operationByState[orderData.status]"
            >
              <template
                v-for="(optKey, optIndex) in operationByState[orderData.status]"
              >
                <order-operation
                  :key="`opt-${optIndex}`"
                  :name="`content.order.orderOperationText.${optKey}`"
                  :icon="optMap[optKey].icon"
                  :fun="optMap[optKey].fun"
                  :ids="[id]"
                  :type="optMap[optKey].type"
                  :callback="getDetail"
                />
              </template>
            </template>
          </el-col>
        </el-row>
      </div>

      <info-list
        title="content.order.storeInfo"
        v-model="store"
        :names="names"
      />
      <info-list title="content.order.userInfo" v-model="user" :names="names" />
      <info-list
        title="content.order.orderInfo"
        v-model="orderData"
        :names="names"
        :exhibit="orderExhibit"
        :to="to"
      />
      <template v-if="orderAttachList && orderAttachList.length">
        <h4 class="detail-h4">{{ $t("content.order.Attach list") }}</h4>
        <sui-list
          ref="orderAttachList"
          :column="orderAttachTableColumn"
          :defaultData="orderAttachList"
        />
      </template>
      <h4 class="detail-h4">{{ $t("content.order.Sub order list") }}</h4>
      <sui-list
        v-if="orderList.length"
        ref="orderList"
        :column="orderTableColumn"
        :defaultData="orderList"
        :refactor="tableDataRefactor"
      />
      <h4 class="detail-h4">{{ $t("content.order.paymentdetails") }}</h4>
      <sui-list
        v-if="orderPayList.length"
        ref="orderPayList"
        :column="orderPayListColumn"
        :defaultData="orderPayList"
      />
    </div>
  </Layout>
</template>

<script>
import { jsPDF } from "jspdf";
import axios from "axios";
import "jspdf-autotable";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SuiList from "@/components/s-ui/list";
import InfoList from "@/components/InfoList";
import OrderOperation from "@/components/OrderOperation";
import {
  GetOrderDetail,
  ConfirmPrepayment,
  ConfirmNoPrepayment,
  CanceOrder,
  ConfirmBalance,
  ConfirmNoBalance,
  GetOrderPayList,
  UpdateOrderbyAdmin,
  GetPayOrderDetail,
  GetPayOrderGeneratePdf,
  GetUserChinaAddress,
} from "@/utils/api";
import { formatDataTime } from "@/utils";
export default {
  components: {
    Layout,
    PageHeader,
    SuiList,
    OrderOperation,
    InfoList,
  },
  data() {
    return {
      visible: false,
      page: "",
      type: "",
      id: 0,
      title: "menuitems.order.detail",
      items: [
        {
          text: this.$t("menuitems.order.management"),
        },
        {
          text: this.$t("menuitems.order.detail"),
          active: true,
        },
      ],
      orderList: [],
      orderIdList: [],
      //   storeArray: [],
      orderAttachList: [],
      orderPayList: [],
      orderTableColumn: [
        {
          name: "orderNo",
          label: "content.order.orderno",
        },
        {
          name: "productName",
          label: "content.order.productName",
        },
        {
          type: "image",
          name: "productImg",
          label: "content.order.productImg",
        },
        {
          name: "spData",
          label: "content.order.spData",
        },
        {
          name: "pcs",
          label: "content.order.pcs",
        },
        // {
        //   name: "price",
        //   label: "content.order.price",
        //   formatFn: formatToDecimal,
        //   width: 100,
        // },
        {
          type: "price-fixed",
          name: "price",
          label: "content.order.price",
          width: 180,
        },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 180,
        },
        // {
        //   name: "total",
        //   label: "content.order.total",
        //   formatFn: formatToDecimal,
        //   width: 100,
        // },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 180,
        },
      ],
      orderAttachTableColumn: [
        {
          name: "type",
          label: "content.order.type",
        },
        {
          name: "payMethod",
          label: "content.order.payMethod",
        },
        {
          name: "remark",
          label: "content.order.remark",
        },
        {
          type: "image",
          name: "imgList",
          label: "content.order.imgList",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      orderPayListColumn: [
        {
          name: "outTradeNo",
          label: "content.order.orderno",
        },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 150,
        },

        {
          name: "payMethod",
          label: "content.order.payMethod",
          payMethod: this.$t("content.order.payMethodText"),
        },
        {
          name: "codeUrl",
          label: "content.order.payCodeUrl",
        },
        {
          name: "status",
          label: "content.order.status",
          payStatus: this.$t("content.order.payStatusText"),
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      // fun: {
      //   301: ConfirmPrepayment,
      //   302: ConfirmNoPrepayment,
      // },
      operationByState: {
        // 不同状态下，显示不同的操作按钮 当前状态: [操作后状态, 操作后状态]
        101: [1301],
        201: [301, 302],
        501: [601, 602],
      },
      optMap: {
        // 操作按钮枚举
        1301: {
          icon: "el-icon-circle-close",
          type: "danger",
          fun: CanceOrder,
        },
        301: {
          icon: "el-icon-circle-check",
          fun: ConfirmPrepayment,
        },
        302: {
          icon: "el-icon-circle-close",
          fun: ConfirmNoPrepayment,
          type: "danger",
        },
        601: {
          icon: "el-icon-circle-check",
          fun: ConfirmBalance,
        },
        602: {
          icon: "el-icon-circle-close",
          fun: ConfirmNoBalance,
          type: "danger",
        },
      },
      tableDataRefactor: (list) => {
        // console.log("list", list);
        return list.map((v) => {
          return {
            ...v,
            currencyTo: v.orderList[0].currencyTo,
          };
        });
      },
      orderData: {},
      PDFdata: {},
      orderExhibit: {
        status: { type: "state" },
        total: { type: "price" },
      },
      user: {},
      store: {},
      admin_user_token: "",
      pdfID: 0,
      orderStatus: 0,
      OrderWayMode: 0,
    };
  },
  computed: {
    names: function () {
      return this.$t("content.order");
    },
    to: function () {
      return this.orderList[0] ? this.orderList[0].currencyTo : "";
    },
  },
  created() {
    this.id = Number(this.$route.query.id);
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getDetail();

    this.admin_user_token = localStorage.getItem("admin_user_token");
    // this.getOrderPayList();
  },
  methods: {
    getChinaAddress(userId) {
      GetUserChinaAddress({
        userId: userId,
      })
        .then((res) => {
          this.orderData.UserConsignee = res.data.consignee;
          this.orderData.Userphone = res.data.phone;
          this.orderData.Userdetail = res.data.detail;
        })
        .catch((err) => {
          console.log("GetUserChinaAddress-err", err);
        });
    },
    confirmOrderbyAdmin() {
      UpdateOrderbyAdmin({
        idList: this.orderIdList,
        orderSatus: 401,
      })
        .then((res) => {
          console.log("confirmOrder-res", res);
          window.location.reload();
        })
        .catch((err) => {
          console.log("confirmOrder-err", err);
        });
    },
    getOrderPayList() {
      if (this.type === "billing") {
        console.log("99999999", this.orderData);
        if (this.orderData.status == 401) {
          this.orderData.status = 301;
        }
        this.orderPayList = [
          {
            outTradeNo: this.orderData.outTradeNo,
            currencyTo: this.orderData.currencyTo,
            total: this.orderData.total,
            createAt: this.orderData.createAt,
            status: this.orderData.status,
            codeUrl: this.orderData.codeUrl,
            payMethod: this.orderData.payMethod,
          },
        ];
      } else {
        GetOrderPayList({ id: this.id })
          .then((res) => {
            console.log("GetOrderPayList-res", res.data);
            const orderPayList = res.data.rows;
            this.orderPayList = orderPayList.map((v) => {
              return {
                ...v,
                outTradeNo: v.Pay.outTradeNo,
                currencyTo: v.Pay.currencyTo,
                total: v.Pay.total,
                createAt: v.Pay.createAt,
                status: v.Pay.status,
                codeUrl: v.Pay.codeUrl,
                payMethod: v.Pay.payMethod,
              };
            });
            //   console.log('11111111111', this.orderPayList);
          })
          .catch((err) => {
            console.log("GetOrderPayList-err", err);
          });
      }
    },
    // 导出为pdf
    toGetPdf(e) {
      if (e == "logistique") {
        const h = this.$createElement;
        this.$msgbox({
          title: this.$t("common.exportpdf"),
          message: h("div", null, [
            h("table", { style: "width: 100%; border-collapse: collapse;" }, [
              h("tbody", null, [
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.orderno")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.orderNo
                  ),
                ]),
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.consignee")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.consignee
                  ),
                ]),
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.pcs")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.pcs
                  ),
                ]),
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.cbm")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.cbm
                  ),
                ]),
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.logisticsType")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.logisticsType
                  ),
                ]),
                h("tr", null, [
                  h(
                    "td",
                    {
                      style:
                        "border: 1px solid #ccc; padding: 8px;width:150px;",
                    },
                    this.$t("content.order.address")
                  ),
                  h(
                    "td",
                    { style: "border: 1px solid #ccc; padding: 8px;" },
                    this.PDFdata.address
                  ),
                ]),
                // 添加更多行
              ]),
            ]),
          ]),
          showCancelButton: true,
          confirmButtonText: this.$t("common.ok"),
          cancelButtonText: this.$t("common.cancel"),
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";

              // 调用导出 PDF 的函数
              this.getPdf("logistique");

              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 1500);
            } else {
              done();
            }
          },
        }).then((action) => {
          this.$message({
            type: "info",
            message: "action: " + action,
          });
        });
      } else {
        this.getPdf("detail");
      }
    },

    getPdf(e) {
      if (e == "logistique") {
        const doc = new jsPDF({
          orientation: "landscape", // 设置方向为横向
          unit: "mm", // 设置单位为毫米
          format: "a4", // 设置页面格式为 A4
          putOnlyUsedFonts: true,
          floatPrecision: 5, // 设置浮点数精度
        });

        // 设置页面大小和缩放比例
        const scaleFactor = 1.5; // 设置缩放比例为 80%
        const pageWidth = doc.internal.pageSize.getWidth() * scaleFactor;
        const pageHeight = doc.internal.pageSize.getHeight() * scaleFactor;
        console.log("pageWidth", pageWidth, pageHeight);

        // 添加标题
        //   doc.setFontSize(18);
        //   doc.text(this.$t("SOKOGATE"), 14, 20);

        // 添加表格
        const tableData = [
          [this.$t("content.order.orderno"), this.PDFdata.orderNo],
          [this.$t("content.order.consignee"), this.PDFdata.consignee],
          [this.$t("content.order.pcs"), this.PDFdata.pcs],
          [this.$t("content.order.cbm"), this.PDFdata.cbm],
          [
            this.$t("content.order.logisticsType"),
            this.orderData.logisticsType,
          ],
          [this.$t("content.order.address"), this.orderData.address],
          // 添加更多行
        ];

        doc.autoTable({
          head: [["SOKOGATE", ""]],
          body: tableData,
          startY: 10, // 表格开始的 Y 坐标
          theme: "grid", // 表格主题
          styles: { cellPadding: 2, fontSize: 35, fontStyle: "bold" }, // 表格样式
        });

        // 保存 PDF
        doc.save("export.pdf");
      } else if (e == "detail") {
        GetPayOrderGeneratePdf({
          type: 2,
          orderId: this.pdfID,
        })
          .then((res) => {
            const url = "https://api.sokogate.com/static/" + res.data.filepath;

            axios({
              url: url,
              method: "GET",
              headers: {
                "X-Auth-Token": `${this.admin_user_token}`,
              },
              responseType: "blob",
            })
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "OrderLsit.pdf";
                link.click();
              })
              .catch((error) => {
                console.error("下载文件失败:", error);
              });
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },

    processOrderList(res) {
      let mergedOrders = [];
      let totals = {
        total: 0,
        weight: 0,
        pcs: 0,
        cbm: 0,
        currencyTo: "",
        orderNo: "",
        detail: "",
        storeId: "",
        username: "",
        storeName: "",
        ...res.data,
      };
      res.data.orderList.forEach((item) => {
        console.log("000000", item);

        totals.weight += item.weight || 0;
        totals.pcs += item.pcs || 0;
        totals.cbm += item.cbm || 0;
        totals.currencyTo = item.currencyTo;
        totals.orderNo = item.orderNo;
        totals.storeId = item.storeId;
        totals.username = item.username;
        totals.detail = item.detail;
        totals.storeName = item.storeName;
        mergedOrders = [...mergedOrders, ...item.orderList];
      });
      const result = {
        ...totals,
        orderList: mergedOrders,
      };
      if (result.orderList[result.orderList.length - 1].wayMode == 201) {
        this.OrderWayMode = this.$t("content.order.airTransport");
      } else if (result.orderList[result.orderList.length - 1].wayMode == 101) {
        this.OrderWayMode = this.$t("content.order.oceanShipping");
      } else {
        this.OrderWayMode = "Free shipping";
      }

      return result;
    },
    getDetail() {
      const apiMethod =
        this.type === "billing" ? GetPayOrderDetail : GetOrderDetail;
      apiMethod({ id: this.id })
        .then((res) => {
          if (res.data.orderList) {
            this.getChinaAddress(res.data.orderList[0].userId);
            this.pdfID = res.data.orderList[0].id;
            this.orderStatus = res.data.orderList[0].status;
            this.orderIdList = res.data.orderList.map((item) => item.id);
          }

          const OrderDetailList =
            this.type === "billing"
              ? this.processOrderList(res)
              : res.data.OrderDetail;

          const { orderList, orderAttachList, ...orderData } = OrderDetailList;

          this.orderList = orderList.map((v) => {
            return {
              ...v,
              productImg: v.productImg || v.productGalleryImg,
              currencyTo: v.currencyTo,
            };
          });

          this.orderAttachList = orderAttachList;
          this.$refs["orderAttachList"] &&
            this.$refs["orderAttachList"].setList(orderAttachList);
          const {
            // 订单
            orderNo,
            cbm,
            total,
            status,
            createAt,
            updateAt,
            // 店铺
            storeId,
            storeName,
            // codeUrl,
            // 用户
            userId,
            username,
            avatar,
            // 收货地址
            // consignee,
            phone,
            pcs,
            country,
            province,
            city,
            district,
            detail,
            email,
            logisticsType,
            user,
            weight,
            // 其它
            ...others
          } = orderData;

          this.orderData = {
            orderNo,
            currencyTo: orderData.currency,
            outTradeNo: orderData.outTradeNo,
            detail,
            storeName,
            pcs: pcs,
            total: res.data.total,
            cbm: cbm + " / " + weight + "KG",
            status: this.orderStatus,
            createAt: formatDataTime(createAt),
            updateAt: formatDataTime(updateAt),
            consignee:
              this.type === "billing"
                ? `${username} (${phone ? phone : ""} ${email ? email : ""})`
                : `${username} (${user.phoneCode}-${phone ? phone : ""} ${
                    email ? email : ""
                  })`,
            address: `${country ? country : ""} ${province ? province : ""} ${
              city ? city : ""
            } ${district ? district : ""} ${detail ? detail : ""}`,
            logisticsType: this.OrderWayMode,
          };

          this.PDFdata = {
            orderNo,
            country,
            district,
            detail,
            province,
            weight,
            storeName,
            currencyTo: this.orderList[0].currencyTo,
            city,
            pcs,
            total,
            cbm: cbm + " / " + weight + "KG",
            status,
            createAt: formatDataTime(createAt),
            updateAt: formatDataTime(updateAt),
            consignee:
              this.type === "billing"
                ? `${username} (${phone} ${email})`
                : `${username} (${user.phoneCode}-${phone} ${email})`,
            address: `${country} ${province} ${city} ${district} ${detail}`,
            logisticsType:
              logisticsType == 301
                ? this.$t("content.order.airTransport")
                : this.$t("content.order.oceanShipping"),
          };
          this.getOrderPayList();
          this.store = { storeId, storeName };
          this.user = { userId, username, avatar };
          console.log("others", others);
        })
        .catch((err) => {
          console.log("GetOrderDetail-err:", err);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="scss">
.col-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.detail-h4 {
  color: #333;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: normal;
}
</style>